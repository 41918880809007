<template>
  <DIV>

    <div id="container"></div>
  </DIV>
</template>
  
<style>
#container {
  width: 1920px;
  height: 1080px;
  margin: 0 auto;
}
</style>
  
  
<script>

import G6 from '@antv/g6';
import insertCss from 'insert-css';

// 我们用 insert-css 演示引入自定义样式
// 推荐将样式添加到自己的样式文件中
// 若拷贝官方代码，别忘了 npm install insert-css
insertCss(`
    .g6-component-tooltip {
      border: 1px solid #e2e2e2;
      border-radius: 4px;
      font-size: 12px;
      color: #000;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 10px 8px;
      box-shadow: rgb(174, 174, 174) 0px 0px 10px;
    }
  `);

export default {
  data() {
    return {
      // G6实例
      graph: null,
      // 用来记录已经点击过的节点
      currentNode: [],
      // data
      g6Obj: {}
    }
  },
  mounted() {
    this.initGraph()
  },
  methods: {

    initGraph() {
      console.log("XXXXXXXXXnew!!!!")

      const data = {
        nodes: [
          { id: "node1", x: 50, y: 100 },
          { id: "node2", x: 150, y: 100 },
        ],
        edges: [
          {
            source: "node1",
            target: "node2"
          }
        ]
      };
      const graph = new G6.Graph({
        container: 'container',
        width: 500,
        height: 500,
        defaultNode: {
          type: 'dom-node',
          size: [120, 40],
        },
      });

      graph.data(data);
      graph.render();
    }
  }
}
</script>